// form表单信息
const state = {
  defaultMil: '',
  defaultYear: '',
  defaultElePrice: '',
  defaultF: '',
}
const mutations = {
  setAddressRes(state, payload) {
    state.addressRes = payload
  },
}
const getters = {
  getAddressRes: state => {
    return state.addressRes
  },
  getUserName: state => {
    return state.userName
  },
  getMobile: state => {
    return state.mobile
  },
  getIsChecked: state => {
    return state.isChecked
  },
  getCity: state => {
    return state.city
  },
  getProvince: state => {
    return state.province
  },
  getFormValue: state => {
    return state.formValue
  },
  getqyFormValue: state => {
    return state.qyformValue
  },
  getPaysetPayInfoInfo: state => {
    return state.payInfo
  }
}
const actions = {
  setAddressRes: (context, payload) => {
    context.commit('setAddressRes', payload)
  },
  setUserName: (context, payload) => {
    context.commit('setUserName', payload)
  },
  setMobile: (context, payload) => {
    context.commit('setMobile', payload)
  },
  setIsChecked: (context, payload) => {
    context.commit('setIsChecked', payload)
  },
  setCity: (context, payload) => {
    context.commit('setCity', payload)
  },
  setProvince: (context, payload) => {
    context.commit('setProvince', payload)
  },
  setFormValue: (context, payload) => {
    context.commit('setFormValue', payload)
  },
  setqyFormValue: (context, payload) => {
    context.commit('setqyFormValue', payload)
  },
  setPayInfo: (context, payload) => {
    context.commit('setPayInfo', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}