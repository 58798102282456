'use strict';
const state = {
  aId:'',
  rId:'',
  userId:''
}
const mutations = {
  setUserARID (state,payload) {
    state.aId = payload.aId
    state.rId = payload.rId
  },
  setUserId(state, newval) {
    state.userId = newval
  },
}
const getters={
  getUserARID:state=>{
    return {
      aId: state.aId,
      rId: state.rId
    }
  },
  getUserId(state) {
    return state.userId 
  },
}
const actions = {
  setUserARID: (context,payload) => {
    context.commit('setUserARID',payload)
  },
  setUserId: (context, payload) => {
    context.commit('setUserId', payload)
  },
}
 
export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}