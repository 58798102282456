import "babel-polyfill";
import 'core-js/stable'
import { createApp } from "vue";
import store from "@/store";
import router from "./router";
import App from "./App.vue";
import VueCookies from 'vue-cookies'
import '@vant/touch-emulator';
import CommonLoading from './components/CommonLoading'
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies)
app.mount("#app");
import { NavBar, Lazyload, Tab, Tabs, Field, RadioGroup, Radio, ActionSheet, CountDown } from "vant";
app.use(NavBar);
app.use(Lazyload);
app.use(Tab);
app.use(Tabs);
app.use(Field);
app.use(RadioGroup)
app.use(Radio);
app.use(ActionSheet);
app.use(CountDown);

app.component('CommonLoading', CommonLoading)
// 使用vant
import "vant/lib/index.css";
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import "@assets/css/main.less";

app.config.productionTip = false;
