import axios from "axios";
import axiosRetry from 'axios-retry';
import { Toast } from "vant";
import qs from "qs";

import {
  getCookie,
  isNull,
  isApp,
  isAndroid,
  isIos,
  isMiniprogramEnv,
  getTokens,
  miniAppRedirectTo,
  getMiniprogramEnv,
  clearUrlParams,
  clearLocalStorageAndCookies,
} from "./utils";
import { AppLogin }  from './jsbridge'
import store from '@/store'

// axios.defaults.withCredentials = true 表示每次请求都会携带cookie
const accessTokenKey = process.env.VUE_APP_ACCESS_TOKEN_KEY;
const refreshTokenKey = process.env.VUE_APP_REFRESH_TOKEN_KEY;

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 60000,
});
// request拦截器req
service.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    config.headers["X-Requested-with"] = "XMLHttpRequest";
    //判断环境添加请求头 system
    if (isApp()) {
      if (isIos()) {
        config.headers["system"] = "iOS";
      } else if (isAndroid()) {
        config.headers["system"] = "Android";
      }
    } else if (isMiniprogramEnv()) {
      const { isAliapp, isDyapp } = getMiniprogramEnv();
      config.headers["system"] = isAliapp
        ? "xcx_ali"
        : isDyapp
        ? "xcx_douyin"
        : "xcx";
    } else {
      config.headers["system"] = "gw";
    }
    const { aId, rId } = getTokens();
    config.headers["aId"] = aId;
    config.headers["rId"] = rId;
    const { activityCode, leadSourceCode, leadSanSourceCode } = qs.parse(
      window.location.search.substr(1)
    );
    if (activityCode || leadSourceCode || leadSanSourceCode) {
      config.params = {
        ...config.params,
        activityCode,
        leadSourceCode,
        leadSanSourceCode
      };
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if (!isNull(response) && !isNull(response.headers)) {
      let accessToken = response.headers[accessTokenKey];
      let refreshToken = response.headers[refreshTokenKey];
      if (!isNull(accessToken) && !isNull(refreshToken)) {
        localStorage.setItem(accessTokenKey, accessToken);
        localStorage.setItem(refreshTokenKey, refreshToken);
      }
    }

    const res = response.data || {};
    
    // url上拼接noLoginRequired=true 无需跳转登录参数并且code == 203
    if (response.config.params?.noLoginRequired && Number(res.code) == 203) return 
    //错误码为0 和 -1 的时候统一提示
    if (Number(res.code) == 203) {
      console.log(store.getters['common/getIsLogin'])
      if (!store.getters['common/getIsLogin']) {
        store.commit('common/setIsLogin', true)
        setAuthVisible(true);
        return Promise.reject(res)
      } else {
        return Promise.reject(new Error('多次登录'))
      }
    }
    if (Number(res.code) !== 0) {
      Toast({ message: res.msg || "服务器繁忙稍后再试", position: "middle" });
      return Promise.reject(res.msg || "服务器繁忙稍后再试");
    }
    return res.data;
  },
  (error) => {
    // Toast({ message: "请求失败!", position: "middle" });
    console.log(error)
    return Promise.reject(error);
  }
);
// 请求错误重试机制
axiosRetry(service, { 
  retries: 3,
  retryCondition: (error) => {
    //true为打开自动发送请求，false为关闭自动发送请求
    if (error.message === 'Network Error' || error.message === 'ECONNABORTED') {
      return true;
    } else {
      return false;
    }
  }
});

export const setAuthVisible = visible => {
  if (visible) {
    // 先清除本地缓存
    clearLocalStorageAndCookies('aId')
    clearLocalStorageAndCookies('rId')
    if (isMiniprogramEnv()) {
      const redirectUrl = encodeURIComponent(clearUrlParams(window.location.href, ['aId', 'rId']))
      miniAppRedirectTo({
        url: `/pages/login/authorization?webUrl=${redirectUrl}`,
      });
      setTimeout(() => {
        store.commit('common/setIsLogin', false)
      }, 500);
    } else if (isApp()) {
      AppLogin()
      setTimeout(() => {
        store.commit('common/setIsLogin', false)
      }, 1000);
    } else {
      const redirectUrl = encodeURIComponent(clearUrlParams(window.location.href, ['aId', 'rId']))
      window.location.replace(`${process.env.VUE_APP_WAP_PATH}login.html?type=H5XDD&redirect_url=${redirectUrl}`)
      store.commit('common/setIsLogin', false)
    }
  } else {
    store.commit('common/setIsLogin', true)
  }
}

export default service;
