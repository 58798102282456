//神策埋点
const sensors = require("sa-sdk-javascript");
const server_url = process.env.VUE_APP_UBPDATA;// 埋点地址
import { getParam, isApp, getMiniprogramEnv } from '@/utils/utils'
import qs from 'qs'
sensors.init({
  server_url: server_url,
  show_log: true,
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: "image",
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: "default",
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: "not_collect"
  }
});

// 获取distinctID
let distinctID = getParam("distinctID");
console.log('distinctID==>' + distinctID)
let userId = getParam("userId");
sensors.identify(distinctID, true);
sensors.quick("autoTrack"); //用于采集 $pageview 事件。
const { activityCode, leadSourceCode, leadSanSourceCode } = qs.parse(window.location.search.substr(1));
let plat_form =  'web_miniprogram_h5'
const {isWxapp, isAliapp, isDyapp} = getMiniprogramEnv()
if (isApp()) {
  plat_form = 'app_h5'
} else if (isWxapp) {
  plat_form = 'wx_miniprogram_h5'
} else if (isAliapp) {
  plat_form = 'ali_h5'
} else if (isDyapp) {
  plat_form = 'douyin_h5'
}
console.log('plat_form==>' + plat_form);
// 注册公共属性
sensors.registerPage({
  plat_form,
  activityCode,
  leadSourceCode,
  leadSanSourceCode,
  radar_userId: userId, 
  'radar_mobile': localStorage.getItem("mobile"),
});

export default sensors;
