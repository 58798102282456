import { callOldAppWindowMethodWhenAvailable } from './utils'
// APP登录
export const AppLogin = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {  
    console.log('AppLogin', Date.now())
    window.flutter_inappwebview?.callHandler('AppLogin', 'Login')
  } else {
    callOldAppWindowMethodWhenAvailable('AppLogin', 'Login')
  }
}
// 退出登录
export const AppExitLogin = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('AppExitLogin', Date.now())
    window.flutter_inappwebview?.callHandler('AppExitLogin', 'LoginOut')
  } else {
    window.AppExitLogin?.postMessage('LoginOut');
  }
}

// 获取分享信息
export const AppShareData = (obj) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('AppShareData', Date.now())
    window.flutter_inappwebview?.callHandler('AppShareData', JSON.stringify(obj))
  } else {
    window.AppShareData?.postMessage(JSON.stringify(obj));
  }
}

// 打开分享弹框
export const openShare = (obj) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('openShare', Date.now())
    window.flutter_inappwebview?.callHandler('openShare')
  } else {
    window.openShare?.postMessage('');
  }
}

// 隐藏APP分享入口
export const cancelAppShare = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('cancelAppShare', Date.now())
    window.flutter_inappwebview?.callHandler('cancelAppShare')
  } else {
    window.cancelAppShare?.postMessage('');
  }
}

// 监听页面滚动，更改导航背景色和状态栏颜色
export const AppScrollTop = (num) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('AppScrollTop', Date.now())
    window.flutter_inappwebview?.callHandler('AppScrollTop', num)
  } else {
    window.AppScrollTop?.postMessage(num);
  }
}

// 联系在线客服
export const ContactService = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('ContactService', Date.now())
    window.flutter_inappwebview?.callHandler('ContactService', 'service')
  } else {
    window.ContactService?.postMessage('service');
  }
}

// 打客服电话 400-111-8264
export const CallServiceTel = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('CallServiceTel', Date.now())
    window.flutter_inappwebview?.callHandler('CallServiceTel', 'tel')
  } else {
    window.CallServiceTel?.postMessage('tel');
  }
}

// 打客服电话（电话从JS动态获取）
export const CallTel = (hotline) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('CallTel', hotline)
  } else {
    window.CallTel?.postMessage(hotline);
  }
}

// 从APP获取定位信息
export const JSGetLocationFromApp = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSGetLocationFromApp', Date.now())
    window.flutter_inappwebview?.callHandler('JSGetLocationFromApp', 'JSGetLocationFromApp')
  } else {
    window.JSGetLocationFromApp?.postMessage('JSGetLocationFromApp');
  }
}

// 获取App通知权限
export const JSGetNotiFromApp = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSGetNotiFromApp', Date.now())
    window.flutter_inappwebview?.callHandler('JSGetNotiFromApp')
  } else {
    window.JSGetNotiFromApp?.postMessage('');
  }
}

// 跳转App设置页
export const JSOpenAppSettings = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSOpenAppSettings')
  } else {
    window.JSOpenAppSettings?.postMessage('');
  }
}

// 立即订购
export const CarOrderNow = (obj) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('CarOrderNow', Date.now())
    window.flutter_inappwebview?.callHandler('CarOrderNow', JSON.stringify(obj))
  } else {
    window.CarOrderNow?.postMessage(JSON.stringify(obj));
  }
}
// 控制导航显示隐藏和h5页面是否扩展到导航底部
export const setBarStyle = (url) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('setBarStyle', Date.now())
    window.flutter_inappwebview?.callHandler('setBarStyle', url)
  } else {
    window.setBarStyle?.postMessage(JSON.stringify(obj));
  }
}

// 控制导航显示隐藏和h5页面是否扩展到导航底部
export const setBarColor = (obj) => {
  if (!window) return;
  try {
   
    if (window.flutter_inappwebview) {
      waitForJsBridge(() => {
        console.log('setBarColor', Date.now())
        window.flutter_inappwebview?.callHandler('setBarColor', JSON.stringify(obj))
      })
    } else {
      window.setBarStyle?.postMessage(JSON.stringify(obj));
    }
  } catch (error) {
    console.log('setBarColor error', error)
  }
}


// 跳转webView(目前用于小订页跳转协议)
export const JumpWebView = (url) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JumpWebView', Date.now())
    window.flutter_inappwebview?.callHandler('JumpWebView', url)
  } else {
    window.JumpWebView?.postMessage(url);
  }
}
// 跳转webView 状态栏通顶
export const JumpWebViewFull = (url) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JumpWebViewFull', Date.now())
    window.flutter_inappwebview?.callHandler('JumpWebViewFull', url)
  } else {
    window.JumpWebViewFull?.postMessage(url);
  }
}
// 跳转webView 状态栏通顶
export const GetCarH5Title = (title) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('GetCarH5Title', Date.now())
    window.flutter_inappwebview?.callHandler('GetCarH5Title', title)
  } else {
    window.GetCarH5Title?.postMessage(title);
  }
}
// 跳转微信小程序支付
export const AppJumpWeChatMiniProgramPay = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('AppJumpWeChatMiniProgramPay', Date.now())
    window.flutter_inappwebview?.callHandler('AppJumpWeChatMiniProgramPay', JSON.stringify(params))
  } else {
    window.AppJumpWeChatMiniProgramPay?.postMessage(JSON.stringify(params))
  }
}

// 跳转支付宝支付
export const AppJumpAliPay = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('AppJumpAliPay', JSON.stringify(params))
  } else {
    window.AppJumpAliPay?.postMessage(JSON.stringify(params))
  }
}

// 返回上个页面
export const popView = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('popView', '')
  } else {
    window.popView?.postMessage('')
  }
}

// 返回到当前路由首页
export const popToRoot = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('popToRoot', '')
  } else {
    window.popToRoot?.postMessage('')
  }
}

// APP视频播放
export const AppVideoPlay = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('AppVideoPlay', '')
  } else {
    window.AppVideoPlay?.postMessage('')
  }
}

// js回调文章收藏、点赞状态 app更新列表
export const AppUpdateCollectCommentLike = (jsonObj) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('AppUpdateCollectCommentLike', JSON.stringify(jsonObj))
  } else {
    window.AppUpdateCollectCommentLike?.postMessage( JSON.stringify(jsonObj))
  }
}

// 回调活动报名状态 app更新列表
export const AppUpdateActivityApply = (jsonObj) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('AppUpdateActivityApply', JSON.stringify(jsonObj))
  } else {
    window.AppUpdateActivityApply?.postMessage(JSON.stringify(jsonObj))
  }
}
// 申请充电桩
export const JSJumpApplyHHCharge = (extNum) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSJumpApplyHHCharge', extNum)
  } else {
    window.JSJumpApplyHHCharge?.postMessage(extNum)
  }
}

// 调用App点位
export const JSCallAppPT = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppPT', JSON.stringify(params))
  } else {
    window.JSCallAppPT?.postMessage(JSON.stringify(params))
  }
}

// 调用App地图导航
export const JSCallAppNavi = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppNavi', JSON.stringify(params))
  } else {
    window.JSCallAppNavi?.postMessage(JSON.stringify(params))
  }
}
// 调用App Toast 弹框
export const JSCallAppToastCenter = (str) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppToastCenter', str)
  } else {
    window.JSCallAppToastCenter?.postMessage(str)
  }
}
// 调用App Success Toast
export const JSCallAppShowSuccess = (str) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppShowSuccess', str)
  } else {
    window.JSCallAppShowSuccess?.postMessage(str)
  }
}
// 调用App Success Toast
export const JSCallAppShowError = (str) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppShowError', str)
  } else {
    window.JSCallAppShowError?.postMessage(str)
  }
}

// 调用App Success Toast
export const JSCallAppInvite = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppInvite', JSON.stringify(params))
  } else {
    window.JSCallAppInvite?.postMessage(JSON.stringify(params))
  }
}
/**
 * 调用App 获取app下载权限是否开启
 * 版本2.12.0 app新增方法
 * @returns
 */
export const JSCallSavePicturesLimit = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallSavePicturesLimit', 'JSCallSavePicturesLimit')
  }
}
/**
 * 调用App app下载
 * 版本2.12.0 app新增方法
 * @param {*} params  两参数 toastMessage: 成功提示,传了就显示,不传就不显示
 *                          content: bsse64文件
 * @returns
 */
export const JSCallSavePictures = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallSavePictures', JSON.stringify(params))
  }
}

// 查看我的预约
export const JSCallAppComeInMyReservation = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAppComeInMyReservation', '')
  } else {
    window.JSCallAppComeInMyReservation?.postMessage('')
  }
}

// 进入页面自动播放
export const JSCallAutoPlayVideo = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview?.callHandler('JSCallAutoPlayVideo', '')
  } else {
    window.JSCallAutoPlayVideo?.postMessage('')
  }
}

// 获取状态栏和 AppBar 高度
export const JSCallGetAppBarHeight = () => {
  if (!window) return;
  if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
    console.log('AppJumpWeChatMiniProgramPay', Date.now())
    window.flutter_inappwebview?.callHandler('JSCallGetAppBarHeight', '')
  } else {
    window.JSCallGetAppBarHeight?.postMessage('')
  }
}

// H5 DOMContentLoaded 时 隐藏加载框
export const JSCallOnDOMContentLoaded = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSCallOnDOMContentLoaded', Date.now())
    window.flutter_inappwebview?.callHandler('JSCallOnDOMContentLoaded', '')
  } else {
    window.JSCallOnDOMContentLoaded?.postMessage('')
  }
}

// 调用获取登录信息（aId，rId，system）
export const JSCallGetLoginInfo = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSCallGetLoginInfo', Date.now())
    window.flutter_inappwebview?.callHandler('JSCallGetLoginInfo', '')
  } else {
    window.JSCallGetLoginInfo?.postMessage('')
  }
}

// 获取网络连接状态信息
export const JSCallCheckNetwork = () => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSCallCheckNetwork', Date.now())
    window.flutter_inappwebview?.callHandler('JSCallCheckNetwork', '')
  } else {
    window.JSCallCheckNetwork?.postMessage('')
  }
}

// H5动态跳转flutter页面
export const JSCallNativePage = (params) => {
  if (!window) return;
  if (window.flutter_inappwebview) {
    console.log('JSCallNativePage', Date.now())
    window.flutter_inappwebview?.callHandler('JSCallNativePage', JSON.stringify(params))
  } else {
    window.JSCallNativePage?.postMessage(JSON.stringify(params))
  }
}

export function waitForJsBridge(readyCallback, maxAttempts = 20) {
  if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
    readyCallback();
  } else {
    var attempts = 0;
    var checkInterval = setInterval(function() {
      attempts++;
      if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
        clearInterval(checkInterval);
        readyCallback();
      } else if (attempts >= maxAttempts) {
        clearInterval(checkInterval);
        console.log('JsBridge未准备好，已达到最大尝试次数');
      }
    }, 100);
  }
}
