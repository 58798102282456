import Vuex from "vuex";
//vuex持久化插件
import createPersistedState from "vuex-persistedstate";
const VUEX_BASE_KEY = process.env.VUE_APP_VUEX_BASE_KEY;

const modulesFiles = require.context("./modules", true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
const store = new Vuex.Store({
  modules,
  getters: [],
  plugins: [
    createPersistedState({
      key: VUEX_BASE_KEY + "RADAR",
      storage: window.localStorage,
      reducer(val) {
        return {
          user: val.user,
        };
      },
    }),
  ],
});

export default store;
