import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CommonHeaderBar = _resolveComponent("CommonHeaderBar");

  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$options.showCommonHeader ? (_openBlock(), _createBlock(_component_CommonHeaderBar, {
    key: 0
  })) : _createCommentVNode("", true), $data.showPage ? (_openBlock(), _createBlock(_component_router_view, {
    key: 1
  }, {
    default: _withCtx(_ref => {
      let {
        Component
      } = _ref;
      return [(_openBlock(), _createBlock(_KeepAlive, {
        include: $data.includeList
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.fullPath
      }))], 1032, ["include"]))];
    }),
    _: 1
  })) : _createCommentVNode("", true)]);
}