import { isNull } from "../utils/utils";
import request from '@/utils/request'
// 获取用户详细信息
export function getUserMessage(params) {
    return request({
        url: '/common/user/info',
        method: 'GET',
        params
    })
}

// 获取用户详细信息
export function getUserInfoByAid(params) {
    return request({
        url: '/common/user/getUserInfoByAid',
        method: 'GET',
        params
    })
}
// 获取车型信息
export function getCarData(params) {
    return request({
        url: '/goods/carmodel/getCarModelManageById',
        method: 'GET',
        params
    })
}
//获取可试驾城市信息
export function getEnableCityList(version) {
    let url = "/goods/shopToApp/getArea"
    if (!isNull(version) && version.length > 0) {
        url = url + "?version=" + version;
    }
    return request({
        url: url,
        method: 'GET'
    })
}
// 获取0元购信息
export function getZeroOrder(params) {
    return request({
        url: '/order/freeOrder/check',
        method: 'GET',
        params
    })
}
// 获取0元购权益信息
export function getZeroInformation(params) {
    return request({
        url: '/content/agreement/getByCode',
        method: 'GET',
        params
    })
}
// 创建小订订单
export function freeOrder(data) {
    return request({
        url: '/order/mainOrder/create',
        method: 'POST',
        data
    })
}
//0元购预定清单
export function freeOrderPage(data) {
    return request({
        url: '/order/freeOrder/list',
        method: 'POST',
        data
    })
}
//C端校验登录用户是否零元购
export function ExistFreeOrder(params) {
    return request({
        url: '/order/freeOrder/existFreeOrder',
        method: 'GET',
        params
    })
}
// 在线咨询
export function offLine(params) {
    return request({
        url: '/common/common/getServiceUrl',
        method: 'GET',
        params
    })
}
// 验证是否重复订单
export function isExistsPreOrder(params) {
    return request({
        url: '/order/mainOrder/isExistsPreOrder',
        method: 'GET',
        params
    })
}

// 验证是否重复订单
export function getUnboundPreOrder(params) {
    return request({
        url: '/order/mainOrder/getUnboundPreOrder',
        method: 'GET',
        params
    })
}

export function getRestricted(params) {
    return request({
        url: '/goods/area/getCityById',
        method: 'GET',
        params
    })
}

// 创建大定订单
export function createDepositOrder(data) {
    return request({
        url: '/order/mainOrder/create',
        method: 'POST',
        data
    })
}

// 修改大定订单
export function modifyDepositOrder(data) {
    return request({
        url: '/order/mainOrder/modify',
        method: 'POST',
        data
    })
}
// 查询车辆配置信息
export function getCarSaleVersionByModelId(params) {
    return request({
        url: '/goods/configure/getCarSaleVersionByModelId',
        method: 'GET',
        params
    })
}


// 查询车型是否拥有权益
export function getCarModelBenefit(params) {
    return request({
        url: '/goods/carmodel/getCarModelManageByConfigCode',
        method: 'GET',
        params
    })
}