  // form表单信息
  const state = {
    addressRes:'',
    userName:'',
    mobile:'',
    isChecked:'',
    city:'',
    province:'',
    formValue:{ // 个人
      provinceId:'', // 省编码
      cityId:'', // 城市编码
      cityName: '', // 上牌城市
      dealerCode: '', // 经销商ID
      dealerName: '',
      certTypeCode: '',
      certNo: '',
      userName: '',
      userMobile: ''
    },
    qyformValue:{ // 企业
      enterpriseName:'', // 省编码
      enterpriseTaxNo:'', // 城市编码
      qyuserName: '', // 上牌城市
      qyuserMobile: ''
    },
    payInfo:{ // 企业
      provinceId:'', // 省编码
      cityId:'', // 城市编码
      dealerCode: '', // 上牌城市
      dealerName: ''
    }
  }
  const mutations = {
    setAddressRes (state,payload) {
      state.addressRes = payload
    },
    setUserName (state,payload) {
      state.userName = payload
    },
    setMobile (state,payload) {
      state.mobile = payload
    },
    setIsChecked (state,payload) {
      state.isChecked = payload
    },
    setCity (state,payload) {
      state.city = payload
    },
    setProvince (state,payload) {
      state.province = payload
    },
    setFormValue(state,payload){
      state.formValue = payload
    },
    setqyFormValue(state,payload){
      state.qyformValue = payload
    },
    setPayInfo(state,payload){
      state.payInfo = payload
    },
    setCertTypeCode(state,payload){
      state.formValue.certTypeCode = payload
    },
  }
  const getters={
    getAddressRes:state=>{
      return state.addressRes
    },
    getUserName:state=>{
        return state.userName
    },
    getMobile:state=>{
        return state.mobile
    },
    getIsChecked:state=>{
        return state.isChecked
    },
    getCity:state=>{
        return state.city
    },
    getProvince:state=>{
      return state.province
    },
    getFormValue:state=>{
      return state.formValue
    },
    getqyFormValue:state=>{
      return state.qyformValue
    },
    getPaysetPayInfoInfo:state=>{
      return state.payInfo
    },
    getCertTypeCode(state,payload){
      return state.formValue.certTypeCode
    },
  }
  const actions = {
    setAddressRes:(context,payload)=>{
        context.commit('setAddressRes',payload)
    },
    setUserName:(context,payload)=>{
        context.commit('setUserName',payload)
    },
    setMobile:(context,payload)=>{
        context.commit('setMobile',payload)
    },
    setIsChecked:(context,payload)=>{
        context.commit('setIsChecked',payload)
    },
    setCity:(context,payload)=>{
        context.commit('setCity',payload)
    },
    setProvince:(context,payload)=>{
      context.commit('setProvince',payload)
    },
    setFormValue:(context,payload)=>{
      context.commit('setFormValue',payload)
    },
    setqyFormValue:(context,payload)=>{
      context.commit('setqyFormValue',payload)
    },
    setPayInfo:(context,payload)=>{
      context.commit('setPayInfo',payload)
    },
    setCertTypeCode:(context, payload)=>{
      context.commit('setCertTypeCode', payload)
    },
    clearPayInfoAndFormValue: (context, payload) => {
      context.commit('setFormValue', {})
      context.commit('setPayInfo', {})
    }
  }
 
export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}