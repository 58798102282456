'use strict';
const state = {
  isLogin: false,
  taskbarHeight: 0,
  barHeight: 0,
  isGradientStyleBar: false,
  navBarHeight: 0,
  navBarBackgroundColor: '#fff'
}
const mutations = {
  setIsLogin(state, payload) {
    state.isLogin = payload
  },
  setBarHeight(state, payload) {
    state.taskbarHeight = payload.taskbarHeight
    state.barHeight = payload.barHeight
  },
  setIsGradientStyleBar(state, payload) {
    state.isGradientStyleBar = payload
  },
  setNavBarBackgroundColor(state, payload) {
    return state.navBarBackgroundColor = payload
  }
}
const getters={
  getIsLogin: (state) => {
    return state.isLogin
  },
  getBarHeight: (state) => {
    return state.taskbarHeight + state.barHeight
  },
  getIsGradientStyleBar(state, payload) {
    return state.isGradientStyleBar
  },
  getNavBarBackgroundColor(state) {
    return state.navBarBackgroundColor
  }
}
const actions = {
}
 
export default {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}