export default {
  name: "CommonLoading",
  props: {
    text: {
      type: String,
      default: function _default() {
        return "加载中...";
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  created() {},

  mounted() {// document.getElementById("loading-overlay").style.display = "block"
  },

  methods: {}
};