import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "gradient"
};
import { computed } from "vue";
import { useStore } from 'vuex';
export default {
  __name: 'commonHeaderBar',

  setup(__props) {
    const store = useStore();
    const navBarHeight = computed(() => {
      return store.getters['common/getBarHeight'];
    });
    const isGradientStyle = computed(() => {
      return store.state.common.isGradientStyleBar;
    });
    const navBackgroundColor = computed(() => {
      return isGradientStyle ? 'transparent' : store.getters['common/getNavBarBackgroundColor'];
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "page-header-bar",
        style: _normalizeStyle({
          height: _unref(navBarHeight) + 'px',
          backgroundColor: _unref(navBackgroundColor)
        })
      }, [_unref(isGradientStyle) ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true)], 4);
    };
  }

};