import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-22382f28"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");

  const _component_van_overlay = _resolveComponent("van-overlay");

  return _openBlock(), _createBlock(_component_van_overlay, {
    class: "loading-overlay",
    id: "loading-overlay",
    show: $props.show,
    "z-index": "999999"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.show ? (_openBlock(), _createBlock(_component_van_loading, {
      key: 0,
      color: "#3B4C39",
      "text-color": "#fff",
      size: "44px",
      vertical: ""
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.text), 1)]),
      _: 1
    })) : _createCommentVNode("", true)])])]),
    _: 1
  }, 8, ["show"]);
}